import { useState , useEffect} from "react";
import "./css/estilo.css";
import InputMask from "react-input-mask";
import moment from "moment";
import $ from "jquery";

const Formulario = (props) => {
  const [inputs, setInputs] = useState(props.inputs ? props.inputs : {});
  const [message, setmessage] = useState([]);
  const [alert, setalert] = useState(false);
  const [loading, setloading] = useState();
  const [style, setstyle] = useState("");
  const [file, setFile] = useState("");
  const [CPF, setCPF] = useState("");
  const [files, setFiles] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [documento, setDocumento] = useState([]);
  const [documentosVariados,setDocumentosVariados] = useState(props.documentosVariados);
  useEffect(() => {
    setDocumentosVariados(props.documentosVariados);
  }, [props.documentosVariados]); // Dependência que irá disparar sempre que 'documentosVariados' mudar

  const addFile = () => {
    if (!file) {
      return;
    }

    if (
      files.filter((f) => {
        if (f.name === file.name) {
          return true;
        }
        return false;
      }).length
    ) {
      return;
    }

    setFiles([...files, file]);
    setFile("");
    document.getElementById("file").value = "";
  };

  const addDocumentos = () => {
    if (!documento || !documento.name) {
      return;
    }
  
    if (!inputs["tipo"]) {
      setalert("true");
      setstyle("danger");
      setmessage("O tipo de documento é obrigatório.");
      $(".modal-body").animate({ scrollTop: 0 }, "slow");
      return;
    }
  
    // if (documentos.some(f => f.doc.name === documento.name)) {
    //   return;
    // }
  
    const objDoc = {
      doc: documento,
      tipo: inputs["tipo"]
    };
    document.getElementById("documento").value = "";
    setDocumentos([...documentos, objDoc]);
    setDocumento(null);
    setInputs(prev => ({ ...prev, tipo: "" })); // Resetando o tipo no estado
  };
  


  const removeFile = (i) => {
    var file = files.filter(function (ele, index) {
      return index !== i;
    });
    document.getElementById("documento").value = "";
    setFiles(file);
  };

  const removeDocumento = (i) => {
    var doc = documentos.filter(function (ele, index) {
      return index !== i;
    });

    document.getElementById("documento").value = "";
    document.getElementById("tipo").value = "";
    setDocumentos(doc);
  };


  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    salvar();
  };

  function validaCPF(cpf) {
    if( cpf.length !== 11 ||
      cpf === "00000000000" ||
      cpf === "11111111111" ||
      cpf === "22222222222" ||
      cpf === "33333333333" ||
      cpf === "44444444444" ||
      cpf === "55555555555" ||
      cpf === "66666666666" ||
      cpf === "77777777777" ||
      cpf === "88888888888" ||
      cpf === "99999999999" 
    ) {
      return false
    } else {
      return true;
    }
  }

  const buscarCPF = async () => {
    if (CPF === "") {
      return;
    }

    await fetch("https://www.hebertengenharia.com.br/api/candidatarporcpf", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ cpf: CPF, vaga: props.vaga }),
    })
      .then((response) => response.json())
      .then((r) => {
        if (r.result) {
          setalert("true");
          setstyle("success");
          setmessage(r.message);
          setCPF("");
        } else {
          setalert("true");
          setstyle("danger");
          setmessage(r.message);
          setCPF("");
        }
      });
  };

  const salvar = async () => {
    let formData = new FormData();
    setloading(true);


      // Verificação se todos os tipos de documentos foram selecionados
      const tiposSelecionados = documentos.map(doc => doc.tipo);
      const tiposEsperados = ["RG", "CPF", "Comprovante de residencia", "Título de Eleitor", "Carteira de Trabalho"];
      
      const tiposFaltando = tiposEsperados.filter(tipo => !tiposSelecionados.includes(tipo));
  
      if (tiposFaltando.length > 0) {
          setalert("true");
          setstyle("danger");
          setmessage(`Os seguintes tipos de documentos estão faltando: ${tiposFaltando.join(", ")}`);
          $(".modal-body").animate({ scrollTop: 0 }, "slow");
          setloading(false);
          return;
      }

      
    
    if (!moment(inputs["datanascimento"], "DD/MM/YYYY").isValid()) {
      setalert("true");
      setstyle("danger");
      setmessage("Data inserida é inválida.");
      $(".modal-body").animate({ scrollTop: 0 }, "slow");
      setloading(false);
      return;
    }

    if (!validaCPF(inputs["cpf"].replace(/[^0-9]/g, ""))) {
      setalert("true");
      setstyle("danger");
      setmessage("O campo cpf inválido.");
      $(".modal-body").animate({ scrollTop: 0 }, "slow");
      setloading(false);
      return;
    } 
 
    if (!inputs["autorizacao"]) {
      setalert("true");
      setstyle("danger");
      setmessage("O checkbox autorização é obrigatório.");
      $(".modal-body").animate({ scrollTop: 0 }, "slow");
      setloading(false);
      return;
    }

    if (files.length === 0) {
      setalert("true");
      setstyle("danger");
      setmessage("Para cadastrar um anexo você deve clicar no botão +");
      $(".modal-body").animate({ scrollTop: 0 }, "slow");
      setloading(false);
      return;
    }


    files.forEach((file) => {
      formData.append("files[]", file);
    });
  

    documentos.forEach(({ doc, tipo }) => {
      formData.append("documentos[]", doc);
      formData.append("tipos_documento[]", tipo);
    });
 
    inputs["vaga"] = props.vaga;
    inputs["vaga_titulo"] = props.vaga_titulo;
    formData.append("curriculo", JSON.stringify(inputs));
    
    await fetch("https://www.hebertengenharia.com.br/api/salvarcandidato", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      //.then((response) => response.json())
      .then((result) => {
        if (result) {
          setalert("true");
          setstyle("success");
          setmessage("Cadastro realizado com sucesso. Aguarde o contato da nossa equipe!");
          setInputs({});
          setFiles([]);
          setDocumentos([]);
          document.getElementsByName("autorizacao")[0].checked = false;
          document.getElementById("file").value = "";
          document.getElementById("documento").value = "";
          $(".modal-body").animate({ scrollTop: 0 }, "slow");
          setloading(false);
        } else {
          setalert("true");
          setstyle("danger");
          setmessage("Ocorreu um erro ao tentar gravar os dados");
          setloading(false);
        }
      });
  };

  return (
    <div>
      {alert && (
        <div className={"alert alert-" + style} role="alert">
          {message}
        </div>
      )}
      <div id="cadastro-container">
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          {inputs["vaga"] && (
            <div className="row">
              <div className="col-md-12 divisor">
                <span className="titulo">Já tem cadastro? </span>
                <br />
                <small>
                  Caso já tenha efetuado o cadastro anteriormente basta digitar o
                  seu cpf no campo abaixo para realizar a sua candidatura.
                </small>
              </div>
              <div className="row">
                <div style={{ marginLeft: "18px" }}>
                  <div className="col-md-4" style={{ paddingRight: "0px" }}>
                    <div className="form-group">
                      <label
                        className="col-form-label"
                      >
                        *CPF:
                      </label>
                      <InputMask
                        mask="999.999.999-99"
                        className="form-control"
                        value={CPF || ""}
                        onChange={(e) => {
                          setCPF(e.target.value);
                        }}
                        name="CPF"
                      />
                    </div>
                  </div>
                  <div className="col-md-2 ">
                    <div className="form-group" style={{ marginTop: "22px" }}>
                      <label
                        htmlFor="recipient-telefone"
                        className="col-form-label"
                      ></label>
                      <button
                        type="button"
                        className="btn btn-primary"
                        style={{ width: "100%" }}
                        onClick={() => {
                          buscarCPF();
                        }}
                      >
                        Cadastrar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-12 divisor">
              <span className="titulo">Dados Pessoais </span>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="form-group">
                <label htmlFor="recipient-nome" className="col-form-label">
                  *Nome completo:
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={inputs.nome || ""}
                  onChange={handleChange}
                  name="nome"
                  onInvalid={(e) =>
                    e.target.setCustomValidity("O campo nome é obrigatório")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  required
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="recipient-email" className="col-form-label">
                  *Email:
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={inputs.email || ""}
                  onChange={handleChange}
                  name="email"
                  onInvalid={(e) =>
                    e.target.setCustomValidity("O campo email é obrigatório")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  required
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="recipient-telefone" className="col-form-label">
                  *Telefone:
                </label>
                <InputMask
                  mask="(99)99999-9999"
                  className="form-control"
                  value={inputs.telefone || ""}
                  onChange={handleChange}
                  name="telefone"
                  onInvalid={(e) =>
                    e.target.setCustomValidity("O campo telefone é obrigatório")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="recipient-cpf" className="col-form-label">
                  *CPF:
                </label>
                <InputMask
                  mask="999.999.999-99"
                  className="form-control"
                  value={inputs.cpf || ""}
                  onChange={handleChange}
                  name="cpf"
                  onInvalid={(e) =>
                    e.target.setCustomValidity("O campo cpf é obrigatório")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  required
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="recipient-datanascimento"
                  className="col-form-label"
                >
                  *Data de nascimento:
                </label>
                <InputMask
                  mask="99/99/9999"
                  className="form-control"
                  value={inputs.datanascimento || ""}
                  onChange={handleChange}
                  name="datanascimento"
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "A data de nascimento é obrigatória"
                    )
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  required
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="recipient-habilitacao" className="col-form-label">
                  *Habilitação:
                </label>

                <select
                  type="text"
                  className="form-control"
                  value={inputs.habilitacao || ""}
                  onChange={handleChange}
                  name="habilitacao"
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "O campo habilitacao é obrigatório"
                    )
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  required
                >
                  <option value="">Selecione uma opção</option>
                  <option value="N">Não possui</option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                  <option value="D">D</option>
                  <option value="E">E</option>
                </select>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label
                  htmlFor="recipient-areainteresse"
                  className="col-form-label"
                >
                  *Área de interesse:
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={inputs.areainteresse || ""}
                  onChange={handleChange}
                  name="areainteresse"
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "O campo área de interesse é obrigatório"
                    )
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 divisor">
              <span className="titulo">Endereço</span>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="form-group">
                <label htmlFor="recipient-rua" className="col-form-label">
                  *Rua:
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={inputs.rua || ""}
                  onChange={handleChange}
                  name="rua"
                  onInvalid={(e) =>
                    e.target.setCustomValidity("O campo rua é obrigatório")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  required
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="recipient-rua" className="col-form-label">
                  *Número:
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={inputs.numero || ""}
                  onChange={handleChange}
                  name="numero"
                  onInvalid={(e) =>
                    e.target.setCustomValidity("O campo numero é obrigatório")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  required
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="recipient-complemento" className="col-form-label">
                  Complemento:
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={inputs.complemento || ""}
                  onChange={handleChange}
                  name="complemento"
                  onInvalid={(e) =>
                    e.target.setCustomValidity(
                      "O campo complemento é obrigatório"
                    )
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                />
              </div>
            </div>

            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="recipient-cep" className="col-form-label">
                  *CEP:
                </label>
                <InputMask
                  mask="99999-999"
                  className="form-control"
                  value={inputs.cep || ""}
                  onChange={handleChange}
                  name="cep"
                  onInvalid={(e) =>
                    e.target.setCustomValidity("O campo cep é obrigatório")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="form-group">
                <label htmlFor="recipient-bairro" className="col-form-label">
                  *Bairro:
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={inputs.bairro || ""}
                  onChange={handleChange}
                  name="bairro"
                  onInvalid={(e) =>
                    e.target.setCustomValidity("O campo bairro é obrigatório")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  required
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label htmlFor="recipient-cidade" className="col-form-label">
                  *Cidade:
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={inputs.cidade || ""}
                  onChange={handleChange}
                  name="cidade"
                  onInvalid={(e) =>
                    e.target.setCustomValidity("O campo cidade é obrigatório")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  required
                />
              </div>
            </div>

            <div className="col-md-4">
              <div className="form-group">
                <label htmlFor="recipient-estado" className="col-form-label">
                  *Estado:
                </label>
                <select
                  className="form-control"
                  value={inputs.estado || ""}
                  onChange={handleChange}
                  name="estado"
                  onInvalid={(e) =>
                    e.target.setCustomValidity("O campo estado é obrigatório")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                  required
                >
                  <option value="">Selecione uma opção</option>
                  <option value="AC">Acre</option>
                  <option value="AL">Alagoas</option>
                  <option value="AP">Amapá</option>
                  <option value="AM">Amazonas</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goiás</option>
                  <option value="MA">Maranhão</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PR">Paraná</option>
                  <option value="PE">Pernambuco</option>
                  <option value="PI">Piauí</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraima</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                  <option value="EX">Estrangeiro</option>
                </select>
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-12 divisor">
              <span className="titulo">Anexe abaixo seu <b>currículo</b></span>
              <small className="atencao" style={{ marginLeft: "10px" }}>
              <b>*Após selecionar cada arquivo clique no botão +</b>
              </small>
              <hr />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <input
                  type="file"
                  name="file"
                  id="file"
                  className="form-control"
                  accept=".jpg, .gif, .bmp, .png, .jpeg, .pdf, .doc, .txt, .pps, .ppsx, .xlsx, .xls"
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                />
              </div>
            </div>
          </div>
          {files.length > 0 && (
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="recipient-curriculo" className="col-form-label">
                    Anexos:
                  </label>
                  <table className="table table-sm table-bordered table-striped ">
                    <thead>
                      <tr>
                        <th scope="col">Nome do arquivo</th>
                        <th scope="col" className="text-center">
                          Excluir
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {files &&
                        files.map((arq, i) => {
                          return (
                            <tr key={"arq_" + i}>
                              <th scope="row" className="align-middle"  width="90%">
                                {arq.name}
                              </th>
                              <td className="text-center align-middle" width="10%">
                                <span
                                  className="btn btn-danger"
                                  style={{ padding: "7px 9px 7px 9px" }}
                                  onClick={() => {
                                    removeFile(i);
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-12">
              <span
                className="btn btn-primary"
                style={{
                  padding: "6px 9px 6px 9px",
                  float: "right",
                  marginBottom: "7px",
                  marginTop: "-5px",
                }}
                onClick={() => {
                  addFile();
                }}
              >
                <i className="fa fa-plus"></i>
              </span>
            </div>
          </div>
        
      {documentosVariados > 0 && (
  <>  <br />
          <div className="row">
            <div className="col-md-12 divisor">
              <span className="titulo">Anexe abaixo seus <b>documentos</b></span>
              <small className="atencao" style={{ marginLeft: "10px" }}>
                <b>*Após selecionar cada arquivo clique no botão +</b>
              </small>
              <hr />
            </div>
          </div>
          
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="file"
                  name="documento"
                  id="documento"
                  className="form-control"
                  accept=".jpg, .gif, .bmp, .png, .jpeg, .pdf, .doc, .txt, .pps, .ppsx, .xlsx, .xls"
                  onChange={(e) => {
                    setDocumento(e.target.files[0]);
                    }}
                    
                  />


                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                  <select
                    className="form-control"
                    value={inputs.tipo || ""}
                    onChange={handleChange}
                    name="tipo"
                    id="tipo"
                    onInvalid={(e) =>
                    e.target.setCustomValidity("O campo tipo é obrigatório")
                    }
                
                    onInput={(e) => e.target.setCustomValidity("")}
                  >
                    <option value="">Selecione um tipo de documento</option>
                    <option value="RG">RG </option>
                    <option value="CPF">CPF </option>
                    <option value="Comprovante de residencia">Comprovante de residencia</option>
                    <option value="Título de Eleitor">Título de Eleitor</option>
                    <option value="Carteira de Trabalho">Carteira de Trabalho (Experiência/Contrato)</option>
                  </select>
                  </div>
                </div>

                </div>
                

                {documentos.length > 0 && (
                <div className="row">
                  <div className="col-md-12">
                  <div className="form-group">
                    <label htmlFor="recipient-curriculo" className="col-form-label">
                    Anexos:
                    </label>
                    <table className="table table-sm table-bordered table-striped ">
                    <thead>
                      <tr>
                      <th scope="col">Nome do arquivo</th>
                      <th scope="col" className="text-center">Tipo</th>
                      <th scope="col" className="text-center">
                        Excluir
                      </th>
                      </tr>
                    </thead>
                    <tbody>
                      {documentos &&
                      documentos.map((doc, i) => {
                          return (
                            <tr key={"docs_" + i}>
                              <th scope="row" className="align-middle" width="70%">
                                {doc.doc.name}
                              </th>
                              <th className="text-center align-middle" width="20%" >
                                {doc.tipo}
                              </th>
                              <td className="text-center align-middle" width="10%">
                                <span
                                  className="btn btn-danger"
                                  style={{ padding: "7px 9px 7px 9px" }}
                                  onClick={() => {
                                    removeDocumento(i);
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-12">
              <span
                className="btn btn-primary"
                style={{
                  padding: "6px 9px 6px 9px",
                  float: "right",
                  marginBottom: "7px",
                  marginTop: "-5px",
                }}
                onClick={() => {
                  addDocumentos();
                }}
              >
                <i className="fa fa-plus"></i>
              </span>
            </div>
          </div>
          <br />
          </> )}
          <div className="row estilo">
            <div className="col-md-1 radiob" style={{ marginTop: "34px" }}>
              <input
              className="btn-check"
                value={inputs.autorizacao || ""}
                onChange={(e) => {
                  setInputs((values) => ({
                    ...values,
                    ["autorizacao"]: e.target.checked,
                  }));
                }}
                type="checkbox"
                name="autorizacao"
                required
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    "O checkbox de autorização é obrigatório"
                  )
                }
                onInput={(e) => e.target.setCustomValidity("")}
              />


            </div>
            <div className="col-md-11 text-left texto" style={{ paddingRight: "40px" }}>
              Autorizo a utilização de meus dados pessoais para a finalidade de
              cumprimento das etapas do processo de Recrutamento e Atração e
              Seleção Hebert Engenharia e estou ciente de que posso, a qualquer
              momento, solicitar a exclusão dos meus dados da base de informações
              da empresa. Caso a solicitação de exclusão ocorra durante o processo
              seletivo, automaticamente estarei desistindo da minha participação
              no mesmo.
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-md-12">
              <button
                type="submit"
                className="btn btn-primary"
                style={{ width: "100%" }}
                disabled={loading}
              >
                Cadastrar
                {loading && (
                  <span
                    className="fa fa-cog fa-spin"
                    style={{ marginLeft: "10px" }}
                  ></span>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Formulario;
